
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      // TODO Maybe remove overlay
      const overlay = document.getElementsByClassName("drawer-overlay");
      if (overlay.length && overlay[0] && overlay[0].parentNode) {
        overlay[0].parentNode.removeChild(overlay[0]);
      }
      store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
        qulifiedName: "data-kt-drawer-aside",
        value: "off",
      });
      store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
        qulifiedName: "data-kt-drawer",
        value: "off",
      });
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
    return { router };
  },
});
